<template>
  <div class="home">
    <div class="page-loading" id="loading1">
      <span class="loading">
        <span class="bar bar1"></span>
        <span class="bar bar2"></span>
        <span class="bar bar3"></span>
        <span class="bar bar4"></span>
        <span class="bar bar5"></span>
        <span class="bar bar6"></span>
        <span class="bar bar7"></span>
        <span class="bar bar8"></span>
        <p style="color: white">
          Calculating...
          <br />Estimated Time: ~ 30 seconds, <br />Please do not close the page until the result returns.
        </p>
      </span>
    </div>
    <el-form>
      <div class="main">
        <div class="left">
          <div class="section1">
          <el-form-item label="Fluorescent Molecule">
            <!-- Tracer -->
            <el-select
              v-model="dataForm.TracerValue"
              placeholder="please Enter Tracer"
            >
              <el-option
                v-for="item in Tracer"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Bath Gas Composition">
            <!-- Collider Composition -->
            <el-select
              v-model="dataForm.ColliderValue"
              placeholder="please Enter Collider "
            >
              <el-option
                v-for="item in Collider"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="section2">
          <div class="top">
            <div class="first">
              <p>Mole Fraction</p>
              <input type="text" v-model="dataForm.TracerMole" />
            </div>
            <div class="first">
              <p>Temperature (K)</p>
              <input type="text" v-model="dataForm.Temperature" />
            </div>
            <div class="first">
              <p>Pressure (atm)</p>
              <input type="text" v-model="dataForm.Pressure" />
            </div>
          </div>
          <div class="bottom">
            <div class="first">
              <p>He</p>
              <input
                type="text"
                v-model="dataForm.He"
                v-show="dataForm.ColliderValue !== 'Engineering Air'"
              />
              <div
                v-show="dataForm.ColliderValue == 'Engineering Air'"
              >
                {{ dataForm.He }}
              </div>
            </div>
            <div class="first">
              <p>Ar</p>
              <input
                type="text"
                v-model="dataForm.Ar"
                v-show="dataForm.ColliderValue !== 'Engineering Air'"
              />
              <div
                v-show="dataForm.ColliderValue == 'Engineering Air'"
              >
                {{ dataForm.Ar }}
              </div>
            </div>
            <div class="first">
              <p>H<span>2</span></p>
              <input
                type="text"
                v-model="dataForm.H2"
                v-show="dataForm.ColliderValue !== 'Engineering Air'"
              />
              <div
                v-show="dataForm.ColliderValue == 'Engineering Air'"
              >
                {{ dataForm.H2 }}
              </div>
            </div>
            <div class="first">
              <p>N<span>2</span></p>
              <input
                type="text"
                v-model="dataForm.N2"
                v-show="dataForm.ColliderValue !== 'Engineering Air'"
              />
              <div
                v-show="dataForm.ColliderValue == 'Engineering Air'"
              >
                {{ dataForm.N2 }}
              </div>
            </div>
            <div class="first">
              <p>O<span>2</span></p>
              <input
                type="text"
                v-model="dataForm.O2"
                v-show="dataForm.ColliderValue !== 'Engineering Air'"
              />
              <div
                v-show="dataForm.ColliderValue == 'Engineering Air'"
              >
                {{ dataForm.O2 }}
              </div>
            </div>
            <div class="first">
              <p>CO</p>
              <input
                type="text"
                v-model="dataForm.CO"
                v-show="dataForm.ColliderValue !== 'Engineering Air'"
              />
              <div
                v-show="dataForm.ColliderValue == 'Engineering Air'"
              >
                {{ dataForm.CO }}
              </div>
            </div>
            <div class="first">
              <p>NO</p>
              <input
                type="text"
                v-model="dataForm.NO"
                v-show="dataForm.ColliderValue !== 'Engineering Air'"
              />
              <div
                v-show="dataForm.ColliderValue == 'Engineering Air'"
              >
                {{ dataForm.NO }}
              </div>
            </div>
            <div class="first">
              <p>H<span>2</span>O</p>
              <input
                type="text"
                v-model="dataForm.H2O"
                v-show="dataForm.ColliderValue !== 'Engineering Air'"
              />
              <div
                v-show="dataForm.ColliderValue == 'Engineering Air'"
              >
                {{ dataForm.H2O }}
              </div>
            </div>
            <div class="first">
              <p>CO<span>2</span></p>
              <input
                type="text"
                v-model="dataForm.CO2"
                v-show="dataForm.ColliderValue !== 'Engineering Air'"
              />
              <div
                v-show="dataForm.ColliderValue == 'Engineering Air'"
              >
                {{ dataForm.CO2 }}
              </div>
            </div>
          </div>
        </div>
        <div class="section2 section3">
          <div class="top">
            <div class="first"  style="position: relative; left: 0px">
              <p><img src="@/assets/k6.png" alt="" /></p>
              <input type="text" v-model="dataForm.Vstart" />
            </div>
            <div class="first" style="position: relative; left: 15px">
              <p><img src="@/assets/k7.png" alt="" /></p>
              <input type="text" v-model="dataForm.Vend" />
            </div>
            <div class="first" style="position: relative; left: 35px">
              <p><img src="@/assets/k8.png" alt="" /></p>
              <input type="text" v-model="dataForm.Vstep" />
            </div>
          </div>
          <div class="top">
            <div class="first" style="position: relative; left: 0px">
              <p><img src="@/assets/k9.png" alt="" /></p>
              <input type="text" v-model="λStart" />
            </div>
            <div class="first" style="position: relative; left: 15px">
              <p><img src="@/assets/k10.png" alt="" /></p>
              <input type="text" v-model="λend" />
            </div>
            <div class="first" style="position: relative; left: 35px">
              <p><img src="@/assets/k11.png" alt="" id="img2" /></p>
              <input type="text" v-model="dataForm.LaserCenter" />
            </div>
          </div>
        </div>
        </div>
        <div class="section4">
          <el-button plain @click="submits()">SIMULATE </el-button>
        </div>
      </div>
    </el-form>
    <div class="cont" id="cont">
      <div id="cc">
        <!-- <p>LIF Intensity A.U.</p> -->
        <div style="position: relative">
          <div
            id="EChart"
            style="width: 80vw; height: 500px"
            v-show="stat"
          ></div>
          <!--  <img src="@/assets/c22.png" alt="" style="width: 200px" />-->
          <div style="position: absolute; top: 20px; width: 108%">
            <img src="@/assets/wavelength.png" alt="" style="width: 150px" />
          </div>

          <div style="position: absolute; bottom: 42px; width: 108%">
            <img src="@/assets/wavenumber.png" alt="" style="width: 150px" />
          </div>
        </div>
      </div>
      <div class="c">
        <el-button @click="clear"> Clear Plots</el-button>
        <el-button @click="saveAsPNG">Save .PNG</el-button>
        <el-button @click="saveAsTxt">Save .TXT</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { debounce } from "lodash";
import axios from "axios";
export default {
  data() {
    return {
      stat: true,
      chartInstance: null,
      datalist: require("./processedData"),
      Tracer: [
        {
          value: "NO",
          label: "NO",
        },
        {
          value: "OH",
          label: "OH",
        },
        {
          value: "CH",
          label: "CH",
        },
      ],
      Collider: [
        {
          value: "Engineering Air",
          label: "Engineering Air",
        },
        {
          value: "User Defined",
          label: "User Defined",
        },
      ],

      dataForm: {
        TracerValue: "OH",
        ColliderValue: "Engineering Air",
        Temperature: 1500,
        Pressure: 1.0,

        He: 0,

        Ar: 0,

        H2: 0,

        N2: 0.79,

        O2: 0.21,

        CO: 0,

        NO: 0,

        H2O: 0,

        CO2: 0,
        Vstart: 31500,
        Vend: 32500,
        Vstep: 0.05,
        LaserCenter: 32380.9147,
        TracerMole:0.001,
      },
    };
  },
  methods: {
    saveAsTxt() {
    window.location.href = "https://starlif.pku.edu.cn/chat1.txt";
     //window.location.href = "http://127.0.0.1:5000/chat1";
    },
    clear() {
      this.datalist = [];
      this.getRenderer();
    },
    saveAsPNG() {
      const chartContainer = document.getElementById("cc");
      html2canvas(chartContainer).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = imgData;
        link.download = "STAR LIF Normalized TimeIntegrated LIF.png";
        link.click();
      });
    },
    getRenderer() {
      // 基于准备好的dom，初始化echarts实例
      let EChart = this.$echarts.init(document.getElementById("EChart"));
      // 配置参数
      let config = {
        title: {
          text: "Simulation Result",
          // left: 'center',   // 水平居中
          left: "47%", // 水平居中
          top: "0%", // 标题垂直位置
          textStyle: {
            fontSize: 18, // 标题文本的字体大小
            fontWeight: "bold", // 标题文本的字体粗细
            color: "#808080", // 标题文本的颜色
          },
        },
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            // type: "shadow",
          },
          formatter: function (params) {
            if (params[0]) {
              return (
                "X: " + params[0].axisValue + "<br />" + "Y: " + params[0].value
              );
            }
          },
        },
        legend: {
          data: [
            `Molecule = ${this.dataForm.TracerValue}, Mole Fraction= ${this.dataForm.TracerMole}, T = ${this.dataForm.Temperature}K, P = ${this.dataForm.Pressure}atm, \nBath: He = ${this.dataForm.He}, Ar = ${this.dataForm.Ar}, H2 = ${this.dataForm.H2}, N2 = ${this.dataForm.N2}, O2 = ${this.dataForm.O2}, CO = ${this.dataForm.CO}, H2O = ${this.dataForm.H2O}, CO2 = ${this.dataForm.CO2} `,
          ],
          left: "10%",
          top: "16%",
          textStyle: {
            fontSize: 15, // 图例文字的字体大小
            color: "#808080", // 图例文字的颜色
            // fontWeight: 'bolder' // 坐标轴名称文字权重
          },
        },
        xAxis: [
          {
            //下方轴
            type: "category", // 类型为类目轴
            data: this.datalist[0].map(function (value) {
              // 确保data中的元素是整数
              return Math.round(value);
            }),
            name: " ",
            nameLocation: "middle", // 设置名称显示在坐标轴的末端
            nameGap: 25, // 名称与轴线之间的距离
            nameTextStyle: {
              color: "#808080", // 坐标轴名称文字颜色
              fontSize: 15, // 坐标轴名称文字大小
              //fontWeight: 'bolder' // 坐标轴名称文字权重
            },
            axisLabel: {
              show: true, // 显示x轴标签 
              fontSize: 14,
              color: "#808080", // 标签文字颜色
              /*interval: function (index, value) {
                // index: 当前标签的索引
                // value: 当前标签的值
                // 返回一个布尔值，决定是否显示标签
                return index % 15000 === 0; // 每隔15000个标签显示一个
              },*/
            },
            axisLine: {
              show: true, // 显示x轴轴线
              lineStyle: {
                color: "#808080", // 轴线颜色
                width: 1, // 轴线宽度
                type: "solid", // 轴线类型
              },
            },
            axisTick: {
              // 坐标轴刻度相关的设置
              show: true, // 显示刻度线
              length: 5, // 刻度线的长度
              lineStyle: {
                color: "#808080", // 刻度线的颜色
                width: 1, // 刻度线的线宽
              },
            },
            position: "bottom",
          },

          {
            // 上方轴
            type: "category",
            data: this.datalist[0].map(function (value) {
              // 转换成nm
              return (1e7 / value).toFixed(2);
            }),
            name: "",
            nameLocation: "middle",
            nameGap: 25,
            nameTextStyle: {
              color: "#cccccc",
              fontSize: 15,
            },
            axisLabel: {
              show: true,
              fontSize: 14,
              color: "#cccccc",
              /*interval: function (index, value) {
                // index: 当前标签的索引
                // value: 当前标签的值
                // 返回一个布尔值，决定是否显示标签
                return index % 15000 === 0; // 每隔15000个标签显示一个
              },*/
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#cccccc",
                width: 1,
                type: "solid",
              },
            },
            axisTick: {
              show: true,
              length: 5,
              lineStyle: {
                color: "#cccccc",
                width: 1,
              },
            },
            // 将新的横坐标轴放置在图表的上方
            position: "top",
          },
        ],
        yAxis: {
          min: -0.02, // 设置 y 轴的最小值
          max: 1.2, // 设置 y 轴的最大值
          type: "value",
          splitLine: false,
          data: this.datalist[1],
          name: "Normalized Time-Integrated LIF Signal (a.u.)",
          nameLocation: "middle", // 设置名称显示在坐标轴的末端
          nameGap: 40, // 名称与轴线之间的距离
          nameTextStyle: {
            color: "#808080", // 坐标轴名称文字颜色
            fontSize: 15, // 坐标轴名称文字大小
            //fontWeight: 'bolder' // 坐标轴名称文字权重
          },
          axisLabel: {
            show: true, // 显示y轴标签
            fontSize: 14, // 标签文字大小
            color: "#808080", // 标签文字颜色
            formatter: function (value) {
              return value.toFixed(1); // 将标签格式化为科学计数法，保留两位小数
            },
          },
          axisLine: {
            show: true, // 显示y轴轴线
            lineStyle: {
              color: "#808080", // 轴线颜色
              width: 1, // 轴线宽度
              type: "solid", // 轴线类型x
            },
          },
          axisTick: {
            // 坐标轴刻度相关的设置
            show: true, // 显示刻度线
            length: 5, // 刻度线的长度
            lineStyle: {
              color: "#808080", // 刻度线的颜色
              width: 1, // 刻度线的线宽
            },
          },
        },
        dataZoom: [
          {
            type: "slider",
            show: true,
            xAxisIndex: [0, 1],
            start: 0,
            end: 100,
            // top: '470px',    // 上边缘离容器的距离
            bottom: "2%",
            height: "25px", // 控件高度
          },
        ],
        grid: {
          show: true, // 显示网格边框
          containLabel: true, // 显示网格边框
          left: "5%", // 网格离容器左侧的距离
          right: "1%", // 网格离容器右侧的距离
          top: "10%", // 网格离容器顶部的距离
          bottom: "14%", // 网格离容器底部的距离
          borderWidth: 1, // 边框宽度
          borderColor: "#cccccc", // 边框颜色
        },
        series: [
          {
            symbol: "none",
            name: `Molecule = ${this.dataForm.TracerValue}, Mole Fraction= ${this.dataForm.TracerMole}, T = ${this.dataForm.Temperature}K, P = ${this.dataForm.Pressure}atm, \nBath: He = ${this.dataForm.He}, Ar = ${this.dataForm.Ar}, H2 = ${this.dataForm.H2}, N2 = ${this.dataForm.N2}, O2 = ${this.dataForm.O2}, CO = ${this.dataForm.CO}, H2O = ${this.dataForm.H2O}, CO2 = ${this.dataForm.CO2} `,
            type: "line",
            data: this.datalist[1],
            lineStyle: {
              normal: {
                // color: '#c23531', // 线条颜色
                width: 1.5, // 线条粗细，单位为 px
                type: "solid", // 线条类型
              },
            },
          },
        ],
      };
      // 设置参数
      EChart.setOption(config);
    },
    submits() {
      let He = parseFloat(this.dataForm.He);
      let Ar = parseFloat(this.dataForm.Ar);
      let H2 = parseFloat(this.dataForm.H2);
      let N2 = parseFloat(this.dataForm.N2);
      let O2 = parseFloat(this.dataForm.O2);
      let CO = parseFloat(this.dataForm.CO);
      let NO = parseFloat(this.dataForm.NO);
      let H2O = parseFloat(this.dataForm.H2O);
      let CO2 = parseFloat(this.dataForm.CO2);

      if (this.dataForm.TracerMole <0 || this.dataForm.TracerMole > 1) {
        this.$message({
          message:
            "Recheck the input parameters.",
          type: "error",
          offset: window.screen.height / 2,
        });
        return;
      }
      if (this.dataForm.Temperature <0 || this.dataForm.Pressure < 0) {
        this.$message({
          message:
            "Recheck the input parameters.",
          type: "error",
          offset: window.screen.height / 2,
        });
        return;
      }
      if (this.dataForm.Vstart <10000 || this.dataForm.Vend < this.dataForm.Vstart || this.dataForm.Vstep <0) {
        this.$message({
          message:
            "Recheck the input parameters.",
          type: "error",
          offset: window.screen.height / 2,
        });
        return;
      }
      if (this.dataForm.LaserCenter <0) {
        this.$message({
          message:
            "Recheck the input parameters.",
          type: "error",
          offset: window.screen.height / 2,
        });
        return;
      }

      // 计算总和
      const sum = He + Ar + H2 + N2 + O2 + CO + NO + H2O + CO2;
      console.log(sum.toFixed(2));
      if (sum.toFixed(2) !== "1.00") {
        const normalizedSum = sum; // 归一化使用的总和值
        He /= normalizedSum;
        Ar /= normalizedSum;
        H2 /= normalizedSum;
        N2 /= normalizedSum;
        O2 /= normalizedSum;
        CO /= normalizedSum;
        NO /= normalizedSum;
        H2O /= normalizedSum;
        CO2 /= normalizedSum;

        // 重新设置归一化后的值到 this.dataForm 中，以便后续使用
        this.dataForm.He = He.toFixed(2);
        this.dataForm.Ar = Ar.toFixed(2);
        this.dataForm.H2 = H2.toFixed(2);
        this.dataForm.N2 = N2.toFixed(2);
        this.dataForm.O2 = O2.toFixed(2);
        this.dataForm.CO = CO.toFixed(2);
        this.dataForm.NO = NO.toFixed(2);
        this.dataForm.H2O = H2O.toFixed(2);
        this.dataForm.CO2 = CO2.toFixed(2);

        // 显示加载动画
        // document.getElementById('loading1').style.display = 'block';
      } else {
        this.dataForm.λStart = this.λStart;
        this.dataForm.λend = this.λend;
        document.getElementById("loading1").style.display = "block";
        axios
          .post("https://starlif.pku.edu.cn/plot1", this.dataForm, {
          //.post("http://127.0.0.1:5000/plot1", this.dataForm, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            document.getElementById("loading1").style.display = "none";
            console.log(res.data);
            this.datalist = res.data;
            this.getRenderer();
          })
          .catch((error) => {
            document.getElementById("loading1").style.display = "none";
            this.$message({
              message:
                "Calculation error. Please check the input parameters. It may not cover the excited transitions.",
              type: "error",
              offset: window.screen.height / 2,
            });
            console.error("Error:", error);
          });
      }
    },
  },
  mounted() {
    console.log(this.datalist);
    this.getRenderer();
  },

  computed: {
    λStart: {
      get() {
        return (10000000 / this.dataForm.Vstart).toFixed(4);
      },
      set: debounce(function (value) {
        this.dataForm.Vstart = (10000000 / value).toFixed(4);
      }, 1000), // 设置防抖延迟时间为500毫秒
    },
    λend: {
      get() {
        return (10000000 / this.dataForm.Vend).toFixed(4);
      },
      set: debounce(function (value) {
        this.dataForm.Vend = (10000000 / value).toFixed(4);
      }, 1000), // 设置防抖延迟时间为500毫秒
    },
  },
  watch: {
    "dataForm.ColliderValue": {
      handler: function (val) {
        if (val == "Engineering Air") {
          this.dataForm.He = 0;
          this.dataForm.Ar = 0;
          this.dataForm.H2 = 0;
          this.dataForm.N2 = 0.79;
          this.dataForm.O2 = 0.21;
          this.dataForm.CO = 0;
          this.dataForm.NO = 0;
          this.dataForm.H2O = 0;
          this.dataForm.CO2 = 0;
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.el-button {
  background-color: #8b0012;/* 按钮背景色 */
  color: rgb(255, 255, 255); /* 按钮文本颜色 */
}

#img2 {
  transform: scale(2.7);
  position: relative;
  left: 35px;
}

#cc {
  display: flex;
  align-items: center;
}
#cc2 {
  display: flex;
  align-items: center;
}
.cont p {
  color: #808080;
  display: inline-block;
  transform: rotate(-90deg); /* 逆时针旋转 90 度 */
  transform-origin: left top; /* 设置旋转原点为左上角 */
  white-space: nowrap;
  width: 10px;
}
.cont span {
  color: #808080;
}
.cont .c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 550px;
}
.cont .c .el-button {
  margin: 20px;
  width: 10vw;
}
.cont {
  background: white;
  border-radius: 10px;
  margin: 20px;
  display: flex;
  padding: 10px;
}
.main {
  display: flex;
  height: 200px;
  background: white;
  margin: 20px;
  border-radius: 10px;
  justify-content: space-around;
  /* align-items: center; */
  padding: 10px;
}
.main .section1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main .section2 {
  display: flex;
  margin: 0 20px;
  width: 500px;
  justify-content: space-between;
  flex-direction: column;
}
.main .section2 .top {
  display: flex;
  justify-content: space-between;
}
.main .section2 .top input {
  font-family: Arial, Helvetica, sans-serif;
  width: 80%;
  color: #808080;
}
.main .section2 .top span {
  font-size: 25px;
}
.main .section2 .first {
  width: 50%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}
.main .section2 .first img {
  /* 标识大小更改 */
  width: 75px;
  transform: scale(1.2);
  margin-left: -5px;
}
.main .section2 .first span {
  font-size: 12px;
  /* transform: scale(0.4); */
}
.main .section2 .first div {
  color: #808080;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
}
.main .section2 .bottom {
  display: flex;
  width: 95%;
}

.main .section2 p {
  text-align: left;
  color: #808080;
  /* padding-left: 1.5em; */
}
.main .section2 input {
  outline: none;
  border: none;
  text-align: left;
  border-bottom: 1px solid #808080;
  margin: 0 5px;
}
.main .section2 .bottom .first {
  width: 19%;
  margin: 0 4px;
}
.main .section2 .bottom .first input {
  width: 95%;
  color: #808080;
}
.el-form-item {
  margin-bottom: 0;
}
.main .section3 input {
  outline: none;
  border: none;
  border-bottom: 1px solid #808080;
  margin: 0 5px;
  position: relative;
  left: -35px;
}
.section4 {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.left {
  display: flex;
  width: 80vw;
  justify-content: space-between;
  padding: 10px;
}
</style>
